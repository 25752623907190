import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Sizing from "@/components/general/Sizing"
import MenuList from "@/components/general/MenuList"
import SEO from "@/components/seo"
import Cursor from "@/components/general/Cursor"
import styles from "@/styles/index.module.scss"
import logo from "@/images/logo-sq.png"

import boredom from "@/images/covers/_boredom.jpg"
import racism from "@/images/covers/_racism.jpg"
import disease from "@/images/covers/_disease.jpg"
import climate from "@/images/covers/_climate.jpg"
import hunger from "@/images/covers/_hunger.jpg"
import rents from "@/images/covers/_rents.jpg"
import defaultImg from "@/images/covers/_default.jpg"

const IndexPage = ({pageContext}) => {
    const [cover, setCover] = useState("default")
    const [mouseActive, setMouseActive] = useState(false)
    const ref = useRef()

    useEffect(() => {
        console.log(cover)
    }, [cover])

    useEffect(() => {
        const mouseenter = () => {
            setMouseActive(true)
        }

        const mouseleave = () => {
            setMouseActive(false)
        }

        ref.current.addEventListener("mouseenter", mouseenter)
        ref.current.addEventListener("mouseleave", mouseleave)

        return () => {
            ref.current.removeEventListener("mouseenter", mouseenter)
            ref.current.removeEventListener("mouseleave", mouseleave)

            setMouseActive(false)
        }
    }, [])

    return (
        <>
            <SEO
                title="Free Markets Destroy | A Project of Washington Policy Center"
                description="Across a wide range of important issues, our world is in desperate need of real progress. Learn how markets can help us build a better world."
            />

            <div className={styles.main}>
                <Sizing
                    styles={{
                        position: "fixed",
                        height: "100%",
                        left: 0,
                        top: 0,
                    }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 12,
                                display: "block",
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                width: 13,
                            },
                        },
                    ]}
                >
                    <div className={styles.headerPadding}>
                        <div className={styles.wrap}>
                            <div className={styles.covers}>
                                <img src={defaultImg} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.boredom
                                } ${cover === "boredom" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={boredom} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.climate
                                } ${cover === "climate" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={climate} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${
                                    styles.disease
                                } ${cover === "disease" ? styles.active : ""}`}
                            >
                                {" "}
                                <img src={disease} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.hunger} ${
                                    cover === "hunger" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={hunger} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.rents} ${
                                    cover === "rents" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={rents} alt="" />{" "}
                            </div>
                            <div
                                className={`${styles.covers} ${styles.rents} ${
                                    cover === "racism" ? styles.active : ""
                                }`}
                            >
                                {" "}
                                <img src={racism} alt="" />{" "}
                            </div>
                        </div>
                    </div>
                </Sizing>

                <Sizing
                    styles={{ boxSizing: "border-box", float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                // marginLeft: 13,
                                width: 24,
                                padding: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                // marginLeft: 13,
                                width: 12,
                                padding: 1.5,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                // marginLeft: 13,
                                width: 11,
                                padding: 1.5,
                            },
                        },
                    ]}
                >
                    <div className={"copy--lead " + styles.mobileBg}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        display: "block",
                                        width: 20,
                                        paddingTop: 8,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        // paddingBottom: 1.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        display: "none",
                                    },
                                },
                            ]}
                        >
                            <img style={{ width: "100%" }} src={logo} alt="" />
                        </Sizing>

                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        padding: 1.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        padding: 0,
                                    },
                                },
                            ]}
                        >
                            <p>
                                Disease. Hunger. Climate Change. Across a wide
                                range of important issues, our world is in
                                desperate need of real progress. Our politics
                                are dysfunctional, driven to extremes by
                                polarization and rigged to benefit the
                                politically connected.
                            </p>
                        </Sizing>
                    </div>
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    padding: 1.5,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    padding: 0,
                                },
                            },
                        ]}
                    >
                        <p className={"copy"}>
                            Though we’ve made incredible gains as a society when
                            it comes to civil rights, far too many people are
                            being denied access to transformative
                            opportunities—jobs, healthcare, affordable housing.
                            We need to tear down what’s broken in our politics.
                            We need to destroy the barriers that stand in the
                            way of creating a better world.
                        </p>

                        <div className={"copy"}>
                            <p>
                                How can we bring about this revolution in human
                                progress and sustain it? What if the answer lies
                                in the very thing that so many consider to be a
                                major source of our problems: free market
                                capitalism. What if free markets did more than
                                fill our Amazon carts with new gadgets? What if
                                markets destroyed privilege and poverty? What if
                                markets improved everything?
                            </p>
                        </div>
                    </Sizing>
                    <div className={styles.menuSection}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        paddingTop: 2,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        paddingbottom: 1.5,

                                        // marginBottom: 1,
                                    },
                                },

                                {
                                    min: 1024,
                                    val: {
                                        paddingTop: 2,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingbottom: 0,

                                        // marginBottom: 1,
                                    },
                                },
                            ]}
                        >
                            <Sizing
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            width: 16,
                                            marginBottom: 0.5,
                                        },
                                    },
                                    {
                                        min: 1024,
                                        val: {
                                            width: 6,
                                            marginBottom: 0.5,
                                        },
                                    },
                                ]}
                            >
                                <span className={styles.intro + " copy"}>
                                    Choose a topic to learn how markets can help
                                    us build a better world.
                                </span>
                            </Sizing>
                            <div className={"hand"} ref={ref}>
                                <MenuList setSelection={setCover} langKey={pageContext.langKey}/>{" "}
                            </div>
                        </Sizing>
                    </div>
                </Sizing>
            </div>

            <Cursor active={mouseActive}></Cursor>
        </>
    )
}

export default IndexPage
